import React from 'react';
import { m } from 'utils/messages/messages';
import { getProtectMonthlyRateByProtect } from 'utils/credit/credit';
import { formatMoneyValue } from 'utils/numbers/numbers';

import Col from './Col';
import SvgProtectRespiteCare from 'components/Atoms/SVG/SvgProtectRespiteCare';
import SvgProtectParentalLeave from 'components/Atoms/SVG/SvgProtectParentalLeave';
import SvgProtectShortTimeWork from 'components/Atoms/SVG/SvgProtectShortTimeWork';
import SvgProtectUnemployment from 'components/Atoms/SVG/SvgProtectUnemployment';
import SvgProtectIncapacityForWork from 'components/Atoms/SVG/SvgProtectIncapacityForWork';

import { useDispatch, useSelector } from 'react-redux';
import { setProtect } from 'store/Insurance/Insurance';
import { RootState } from 'store/rootReducer';

import ProtectType from 'types/enums/Protect.d';

import styles from './Table.module.scss';

const Table = () => {
	const dispatch = useDispatch();
	const storeProtect = useSelector((state: RootState) => state.Insurance.protect);
	const storeProtectCoverage = useSelector((state: RootState) => state.Insurance.protectCoverage);

	const setValue = (value: ProtectType) => {
		dispatch(setProtect(value));
	};

	const firstColArray = [
		{ type: 'headline-spacer' },
		{
			type: 'spacer',
			content: m(
				'page.customerData.insurance.protect.body.table.spacer.monthlyPayment',
				'global'
			)
		},
		{
			type: 'text',
			content: m('page.customerData.insurance.protect.body.table.row1.title', 'global'),
			popup: (
				<div className={styles.popup}>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectRespiteCare />
						</div>
						<p className={styles.popupHeadline}>
							{m(
								'page.customerData.insurance.protect.body.table.row1.popup.headline1',
								'global'
							)}
						</p>
					</div>
					<div className={styles.popupText}>
						{m(
							'page.customerData.insurance.protect.body.table.row1.popup.content1',
							'global',
							null,
							true
						)}
					</div>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectParentalLeave />
						</div>
						<p className={styles.popupHeadline}>
							{m(
								'page.customerData.insurance.protect.body.table.row1.popup.headline2',
								'global'
							)}
						</p>
					</div>
					<div className={styles.popupText}>
						{m(
							'page.customerData.insurance.protect.body.table.row1.popup.content2',
							'global',
							null,
							true
						)}
					</div>
				</div>
			)
		},
		{
			type: 'text',
			content: m('page.customerData.insurance.protect.body.table.row2.title', 'global'),
			popup: (
				<div className={styles.popup}>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectShortTimeWork />
						</div>
						<p className={styles.popupHeadline}>
							{m(
								'page.customerData.insurance.protect.body.table.row2.popup.headline1',
								'global'
							)}
						</p>
					</div>
					<div className={styles.popupText}>
						{m(
							'page.customerData.insurance.protect.body.table.row2.popup.content1',
							'global',
							null,
							true
						)}
					</div>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectUnemployment />
						</div>
						<p className={styles.popupHeadline}>
							{m(
								'page.customerData.insurance.protect.body.table.row2.popup.headline2',
								'global'
							)}
						</p>
					</div>
					<div className={styles.popupText}>
						{m(
							'page.customerData.insurance.protect.body.table.row2.popup.content2',
							'global',
							null,
							true
						)}
					</div>
				</div>
			)
		},
		{
			type: 'text',
			content: m('page.customerData.insurance.protect.body.table.row3.title', 'global'),
			popup: (
				<div className={styles.popup}>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectIncapacityForWork />
						</div>
						<p className={styles.popupHeadline}>
							{m(
								'page.customerData.insurance.protect.body.table.row3.popup.headline1',
								'global'
							)}
						</p>
					</div>
					<div className={styles.popupText}>
						{m(
							'page.customerData.insurance.protect.body.table.row3.popup.content1',
							'global',
							null,
							true
						)}
					</div>
				</div>
			)
		},
		{
			type: 'spacer',
			content: m(
				'page.customerData.insurance.protect.body.table.spacer.oneTimePayment',
				'global'
			)
		},
		{
			type: 'text',
			content: m('page.customerData.insurance.protect.body.table.row4.title', 'global'),
			popup: (
				<div className={styles.popup}>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectParentalLeave />
						</div>
						<p className={styles.popupHeadline}>
							{m(
								'page.customerData.insurance.protect.body.table.row4.popup.headline1',
								'global'
							)}
						</p>
					</div>
					<div className={styles.popupText}>
						{m(
							'page.customerData.insurance.protect.body.table.row4.popup.content1',
							'global',
							null,
							true
						)}
					</div>
				</div>
			)
		}
	];

	const standardColArray = [
		{
			type: 'headline',
			content: (
				<>
					<strong>
						{m(
							'page.customerData.insurance.protect.body.table.standard.title',
							'global'
						)}
					</strong>
					<br />
					{(formatMoneyValue(
						getProtectMonthlyRateByProtect(
							ProtectType.STANDARD,
							+(storeProtectCoverage ?? 200)
						),
						true
					) ?? 0) +
						' €/' +
						m('month.label', 'fields')}
				</>
			)
		},
		{ type: 'spacer' },
		{ type: 'empty' },
		{ type: 'check' },
		{ type: 'check' },
		{ type: 'spacer' },
		{ type: 'check' }
	];

	const premiumColArray = [
		{
			type: 'headline',
			content: (
				<>
					<strong>
						{m(
							'page.customerData.insurance.protect.body.table.premium.title',
							'global'
						)}
					</strong>
					<br />
					{(formatMoneyValue(
						getProtectMonthlyRateByProtect(
							ProtectType.PREMIUM,
							+(storeProtectCoverage ?? 200)
						),
						true
					) ?? 0) +
						' €/' +
						m('month.label', 'fields')}
				</>
			)
		},
		{ type: 'spacer' },
		{ type: 'check' },
		{ type: 'check' },
		{ type: 'check' },
		{ type: 'spacer' },
		{ type: 'check' }
	];

	const basicColArray = [
		{
			type: 'headline',
			content: (
				<>
					<strong>
						{m('page.customerData.insurance.protect.body.table.basic.title', 'global')}
					</strong>
					<br />
					{(formatMoneyValue(
						getProtectMonthlyRateByProtect(
							ProtectType.BASIC,
							+(storeProtectCoverage ?? 200)
						),
						true
					) ?? 0) +
						' €/' +
						m('month.label', 'fields')}
				</>
			)
		},
		{ type: 'spacer' },
		{ type: 'empty' },
		{ type: 'empty' },
		{ type: 'check' },
		{ type: 'spacer' },
		{ type: 'check' }
	];

	return (
		<div className={styles.table}>
			<Col rows={firstColArray} isFirst />
			<Col
				rows={standardColArray}
				value={ProtectType.STANDARD}
				onClick={setValue}
				testId="standard-protection"
				selected={storeProtect === ProtectType.STANDARD}
			/>
			<Col
				rows={premiumColArray}
				value={ProtectType.PREMIUM}
				onClick={setValue}
				highlightLabel={m(
					'page.customerData.insurance.protect.body.table.premium.higlightLabel',
					'global'
				)}
				testId="premium-protection"
				selected={storeProtect === ProtectType.PREMIUM}
			/>
			<Col
				value={ProtectType.BASIC}
				rows={basicColArray}
				onClick={setValue}
				testId="basic-protection"
				selected={storeProtect === ProtectType.BASIC}
			/>
		</div>
	);
};
export default Table;
