import React, { useMemo } from 'react';
import Protect from 'types/enums/Protect.d';
import { m } from 'utils/messages/messages';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

type Props = {
	customProtect: string;
};

const ProtectTarif = (props: Props) => {
	const storeProtect = useSelector((state: RootState) => state.Bco.protect);

	const protectTarif = useMemo(() => {
		const protect = props.customProtect ?? storeProtect;
		const protectFormatted = protect ? protect.toLowerCase() : null;
		if (protectFormatted === Protect.STANDARD)
			return m('protect.tarif.items.standard', 'fields');
		if (protectFormatted === Protect.PREMIUM) return m('protect.tarif.items.premium', 'fields');
		if (protectFormatted === Protect.BASIC) return m('protect.tarif.items.basic', 'fields');
		return '';
	}, [storeProtect, props.customProtect]);

	return <>{protectTarif}</>;
};
export default ProtectTarif;
