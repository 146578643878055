import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Insurance from './Insurance.d';
import Protect from 'types/enums/Protect.d';

const initialState: Insurance = {
	protect: null,
	protectRuntime: 60,
	protectCoverage: null
};

export const slice = createSlice({
	name: 'Insurance',
	initialState: initialState,
	reducers: {
		setProtect: (state, action: PayloadAction<Protect>) => {
			state.protect = action.payload;
		},
		setProtectRuntime: (state, action: PayloadAction<number>) => {
			state.protectRuntime = action.payload;
		},
		setProtectCoverage: (state, action: PayloadAction<number>) => {
			state.protectCoverage = action.payload;
		},
		reset: () => initialState
	}
});

export const { setProtect, setProtectRuntime, setProtectCoverage, reset } = slice.actions;
export default slice.reducer;
