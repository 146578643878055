import React from 'react';
import m from 'utils/messages/messages';
import { DATE_INPUT } from 'components/Atoms/Form/TextInput/utils/regex';

import { Text } from 'components/Atoms/Text';
import { Col, Row } from 'components/Atoms/Grid';
import { TextInput } from 'components/Atoms/Form';
import { formatDateForInput } from 'utils/date/date';

type Props = {
	birthdate: string;
	setBirthdate: Function;
	birthdateError: string;
};

const Birthdate = (props: Props) => {
	return (
		<>
			<Text isSubtitle>{m('page.customerData.birthdate.title', 'global')}</Text>
			<Row>
				<Col xs={12}>
					<TextInput
						label={m('birthdate.label', 'fields')}
						value={props.birthdate}
						setValue={props.setBirthdate}
						regex={DATE_INPUT}
						maxLength={10}
						format={formatDateForInput}
						hasError={!!props.birthdateError}
						message={props.birthdateError ?? 'Format: TT.MM.JJJJ'}
						tabindex={5}
						testId="birthdate"
					/>
				</Col>
			</Row>
		</>
	);
};
export default Birthdate;
