import React, { useEffect, useRef } from 'react';
import { m } from 'utils/messages/messages';
import { roundUp } from 'utils/numbers/numbers';

import Table from 'components/Molecules/Form/Insurance/Protect/Table/Table';
import { Text } from 'components/Atoms/Text';
import { DropdownInput, RadioButtonInput } from 'components/Atoms/Form';

import { useDispatch, useSelector } from 'react-redux';
import { setProtect, setProtectCoverage } from 'store/Insurance/Insurance';
import { RootState } from 'store/rootReducer';

import ProtectType from 'types/enums/Protect.d';
import DropdownItem from 'components/Atoms/Form/DropdownInput/types/DropdownItem.d';

const Protect = () => {
	const dispatch = useDispatch();
	const storeProtect = useSelector((state: RootState) => state.Insurance.protect);
	const storeProtectCoverage = useSelector((state: RootState) => state.Insurance.protectCoverage);
	const storeIncome = useSelector((state: RootState) => state.FinancialStatus.income);

	const coverageItems: Array<DropdownItem> = [
		/*{ value: '100', label: '100 €' },*/
		{ value: '200', text: '200 €' },
		{ value: '300', text: '300 €' },
		{ value: '400', text: '400 €' },
		{ value: '500', text: '500 €' },
		{ value: '600', text: '600 €' },
		{ value: '700', text: '700 €' },
		{ value: '800', text: '800 €' },
		{ value: '900', text: '900 €' },
		{ value: '1000', text: '1.000 €' }
	];

	useEffect(() => {
		setDropdowns();
	}, []);

	const isInitialMount = useRef(true);
	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			setDropdowns(true);
		}
	}, [storeIncome]);

	const setDropdowns = (allowReset: boolean = false) => {
		if (storeProtectCoverage === null || allowReset) {
			if (storeIncome) {
				dispatch(setProtectCoverage(roundUp(storeIncome * 0.4, 100, 1000)));
			} else {
				dispatch(setProtectCoverage(parseInt(coverageItems[0].value)));
			}
		}
	};

	const setNoProtect = () => {
		dispatch(setProtect(ProtectType.NO_PROTECT));
	};

	const setProtectCoverageDropdown = (value: string) => {
		dispatch(setProtectCoverage(parseInt(value)));
	};

	return (
		<>
			<Text hasMarginBottom>
				{m('page.customerData.insurance.protect.body.description', 'global')}
			</Text>
			<DropdownInput
				label={m('page.customerData.insurance.protect.body.monthlyProtect.label', 'global')}
				value={storeProtectCoverage + ''}
				setValue={setProtectCoverageDropdown}
				items={coverageItems}
				size="s"
				tabindex={42}
			/>
			<Text isBlack hasMarginBottom>
				{m('page.customerData.insurance.protect.body.selectTitle', 'global')}:
			</Text>
			<Table />
			<RadioButtonInput
				value={storeProtect}
				setValue={setNoProtect}
				items={[
					{
						text: m(
							'page.customerData.insurance.protect.body.table.noProtect',
							'global'
						),
						value: ''
					}
				]}
				tabindex={43}
				testId="no-protect"
			/>
		</>
	);
};
export default Protect;
