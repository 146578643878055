import store from 'store/store';
import { formatDateFromApi, formatYearMonthFromApi } from 'utils/date/date';
import { formatIban } from 'utils/iban/iban';
import { amountDefault, runtimeDefault } from 'constants/Credit';

import { setConditionProgram } from 'store/Company/Company';
import { setHasSecondApplicant, setSameHousehold } from 'store/Applicant/Applicant';
import {
	setAcceptTermsAndConditions,
	setAcceptOwnResponsibility,
	setAcceptSchufa,
	setAcceptAdvertising,
	setAcceptTermsAndConditions2,
	setAcceptSchufa2
} from 'store/OptIn/OptIn';
import {
	setSalutation,
	setFirstName,
	setLastName,
	setSalutation2,
	setFirstName2,
	setLastName2
} from 'store/Name/Name';
import { setDay, setMonth, setYear, setDay2, setMonth2, setYear2 } from 'store/Birthdate/Birthdate';
import { setCustomerNumber } from 'store/Customer/Customer';
import { setEmail } from 'store/Email/Email';
import {
	setCountryCode,
	setMobileNumber,
	setCountryCode2,
	setMobileNumber2
} from 'store/Phone/Phone';
import { setNationality, setNationality2 } from 'store/Nationality/Nationality';
import {
	setPostalCode,
	setCity,
	setStreet,
	setStreetNumber,
	setHasPreviousAddress,
	setPreviousPostalCode,
	setPreviousCity,
	setPreviousStreet,
	setPreviousStreetNumber,
	setPostalCode2,
	setCity2,
	setStreet2,
	setStreetNumber2,
	setHasPreviousAddress2,
	setPreviousPostalCode2,
	setPreviousCity2,
	setPreviousStreet2,
	setPreviousStreetNumber2
} from 'store/Address/Address';
import {
	setMaritalStatus,
	setNumberAdults,
	setNumberChildren,
	setNumberChildrenWithBenefit,
	setMaritalStatus2,
	setNumberAdults2,
	setNumberChildren2,
	setNumberChildrenWithBenefit2
} from 'store/MaritalStatus/MaritalStatus';
import {
	setOccupation,
	setEmployer,
	setOccupationMonth,
	setOccupationYear,
	setOccupationLimited,
	setOccupationLimitedMonth,
	setOccupationLimitedYear,
	setOccupation2,
	setEmployer2,
	setOccupationMonth2,
	setOccupationYear2,
	setOccupationLimited2,
	setOccupationLimitedMonth2,
	setOccupationLimitedYear2
} from 'store/Occupation/Occupation';
import {
	setIban,
	setIncome,
	setAdditionalIncome,
	setLivingCosts,
	setChildSupport,
	setTypeOfLiving,
	setIncome2,
	setAdditionalIncome2,
	setLivingCosts2,
	setChildSupport2,
	setTypeOfLiving2
} from 'store/FinancialStatus/FinancialStatus';
import { setAmount, setRuntime, setExternalCredits } from 'store/Credit/Credit';
import { setBco } from 'store/Bco/Bco';
import { setProtect, setProtectRuntime, setProtectCoverage } from 'store/Insurance/Insurance';

export const setApplicationDataToStore = (data: any) => {
	store.dispatch(setAmount(data?.amount ?? amountDefault));
	store.dispatch(setRuntime(data?.runtime ?? runtimeDefault));
	const externalCredits = data?.applicant?.externalCredits ?? [];
	for (const externalCredit of externalCredits) {
		externalCredit.iban = formatIban(externalCredit.iban);
	}
	store.dispatch(setExternalCredits(externalCredits));
	store.dispatch(setConditionProgram(data?.conditionsProgram ?? null));

	data.bcoResult.id = data?.id ?? null;
	store.dispatch(setBco(data?.bcoResult ?? null));

	store.dispatch(setProtect(data?.bcoResult?.protect ?? null));
	store.dispatch(setProtectRuntime(data?.bcoResult?.protectRuntime ?? null));
	store.dispatch(setProtectCoverage(data?.bcoResult?.protectCoverage ?? null));

	store.dispatch(setAcceptTermsAndConditions(data?.applicant?.acceptTermsAndConditions ?? false));
	store.dispatch(setAcceptOwnResponsibility(data?.applicant?.ownResponsibility ?? false));
	store.dispatch(setAcceptSchufa(data?.applicant?.acceptSchufa ?? false));
	store.dispatch(setAcceptAdvertising(data?.applicant?.allowAdvertising ?? false));

	store.dispatch(setSalutation(data?.applicant?.salutation ?? null));
	store.dispatch(setFirstName(data?.applicant?.firstName ?? null));
	store.dispatch(setLastName(data?.applicant?.lastName ?? null));

	const birthdateApplicant = formatDateFromApi(data?.applicant?.dateOfBirth);
	store.dispatch(setDay(birthdateApplicant?.day ?? null));
	store.dispatch(setMonth(birthdateApplicant?.month ?? null));
	store.dispatch(setYear(birthdateApplicant?.year ?? null));

	store.dispatch(setCustomerNumber(data?.applicant?.customerNumber ?? null));

	store.dispatch(setEmail(data?.applicant?.email ?? null));

	store.dispatch(setCountryCode(data?.applicant?.countryCode ?? null));
	store.dispatch(setMobileNumber(data?.applicant?.mobileNumber ?? null));

	store.dispatch(setNationality(data?.applicant?.nationality ?? 'DE'));

	store.dispatch(setStreet(data?.applicant?.address?.street ?? null));
	store.dispatch(setStreetNumber(data?.applicant?.address?.streetNumber ?? null));
	store.dispatch(setPostalCode(data?.applicant?.address?.postalCode ?? null));
	store.dispatch(setCity(data?.applicant?.address?.city ?? null));

	const hasPreviousAddressApplicant = !!data?.applicant?.previousAddress?.street;
	store.dispatch(setHasPreviousAddress(hasPreviousAddressApplicant));
	if (hasPreviousAddressApplicant) {
		store.dispatch(setPreviousStreet(data?.applicant?.previousAddress?.street ?? null));
		store.dispatch(
			setPreviousStreetNumber(data?.applicant?.previousAddress?.streetNumber ?? null)
		);
		store.dispatch(setPreviousPostalCode(data?.applicant?.previousAddress?.postalCode ?? null));
		store.dispatch(setPreviousCity(data?.applicant?.previousAddress?.city ?? null));
	}

	store.dispatch(setMaritalStatus(data?.applicant?.maritalStatus ?? null));
	store.dispatch(setNumberAdults(data?.applicant?.numberAdults ?? 1));
	store.dispatch(setNumberChildren(data?.applicant?.numberChildren ?? 0));
	store.dispatch(setNumberChildrenWithBenefit(data?.applicant?.numberChildrenWithBenefit ?? 0));

	store.dispatch(setOccupation(data?.applicant?.occupation ?? null));
	store.dispatch(setEmployer(data?.applicant?.employer ?? null));
	const occupiedSinceApplicant = formatYearMonthFromApi(data?.applicant?.occupiedSince);
	store.dispatch(setOccupationMonth(occupiedSinceApplicant?.month ?? null));
	store.dispatch(setOccupationYear(occupiedSinceApplicant?.year ?? null));
	if (data?.applicant?.occupiedUntil) {
		store.dispatch(setOccupationLimited(true));
		const occupiedUntilApplicant = formatYearMonthFromApi(data?.applicant?.occupiedUntil);
		store.dispatch(setOccupationLimitedMonth(occupiedUntilApplicant?.month ?? null));
		store.dispatch(setOccupationLimitedYear(occupiedUntilApplicant?.year ?? null));
	}

	store.dispatch(setIban(data?.applicant?.iban ? formatIban(data.applicant.iban) : null));
	store.dispatch(setIncome(data?.applicant?.income ?? null));
	store.dispatch(setAdditionalIncome(data?.applicant?.additionalIncome ?? null));
	store.dispatch(setLivingCosts(data?.applicant?.livingCosts ?? null));
	store.dispatch(setChildSupport(data?.applicant?.childSupport ?? null));
	store.dispatch(setTypeOfLiving(data?.applicant?.typeOfLiving ?? null));

	if (data?.secondApplicant?.lastName) {
		store.dispatch(setHasSecondApplicant(true));
		const sameHousehold = data?.secondApplicant?.sameHousehold ?? false;
		/*const sameHousehold =
			data?.applicant?.address?.street === data?.secondApplicant?.address?.street &&
			data?.applicant?.address?.streetNumber ===
				data?.secondApplicant?.address?.streetNumber &&
			data?.applicant?.address?.postalCode === data?.secondApplicant?.address?.postalCode &&
			data?.applicant?.address?.city === data?.secondApplicant?.address?.city;*/

		store.dispatch(setSameHousehold(sameHousehold));

		store.dispatch(
			setAcceptTermsAndConditions2(data?.secondApplicant?.acceptTermsAndConditions ?? false)
		);
		store.dispatch(setAcceptSchufa2(data?.secondApplicant?.acceptSchufa ?? false));

		store.dispatch(setSalutation2(data?.secondApplicant?.salutation ?? null));
		store.dispatch(setFirstName2(data?.secondApplicant?.firstName ?? null));
		store.dispatch(setLastName2(data?.secondApplicant?.lastName ?? null));

		const birthdateSecondApplicant = formatDateFromApi(data?.secondApplicant?.dateOfBirth);
		store.dispatch(setDay2(birthdateSecondApplicant?.day ?? null));
		store.dispatch(setMonth2(birthdateSecondApplicant?.month ?? null));
		store.dispatch(setYear2(birthdateSecondApplicant?.year ?? null));

		store.dispatch(setCountryCode2(data?.secondApplicant?.countryCode ?? null));
		store.dispatch(setMobileNumber2(data?.secondApplicant?.mobileNumber ?? null));

		store.dispatch(setNationality2(data?.secondApplicant?.nationality ?? 'DE'));

		store.dispatch(setMaritalStatus2(data?.secondApplicant?.maritalStatus ?? null));

		store.dispatch(setOccupation2(data?.secondApplicant?.occupation ?? null));
		store.dispatch(setEmployer2(data?.secondApplicant?.employer ?? null));
		const occupiedSinceSecondApplicant = formatYearMonthFromApi(
			data?.secondApplicant?.occupiedSince
		);
		store.dispatch(setOccupationMonth2(occupiedSinceSecondApplicant?.month ?? null));
		store.dispatch(setOccupationYear2(occupiedSinceSecondApplicant?.year ?? null));
		if (data?.secondApplicant?.occupiedUntil) {
			store.dispatch(setOccupationLimited2(true));
			const occupiedUntilSecondApplicant = formatYearMonthFromApi(
				data?.secondApplicant?.occupiedUntil
			);
			store.dispatch(setOccupationLimitedMonth2(occupiedUntilSecondApplicant?.month ?? null));
			store.dispatch(setOccupationLimitedYear2(occupiedUntilSecondApplicant?.year ?? null));
		}

		store.dispatch(setIncome2(data?.secondApplicant?.income ?? null));
		store.dispatch(setAdditionalIncome2(data?.secondApplicant?.additionalIncome ?? null));

		if (!sameHousehold) {
			store.dispatch(setStreet2(data?.secondApplicant?.address?.street ?? null));
			store.dispatch(setStreetNumber2(data?.secondApplicant?.address?.streetNumber ?? null));
			store.dispatch(setPostalCode2(data?.secondApplicant?.address?.postalCode ?? null));
			store.dispatch(setCity2(data?.secondApplicant?.address?.city ?? null));

			const hasPreviousAddressSecondApplicant =
				!!data?.secondApplicant?.previousAddress?.street;
			store.dispatch(setHasPreviousAddress2(hasPreviousAddressSecondApplicant));
			if (hasPreviousAddressSecondApplicant) {
				store.dispatch(
					setPreviousStreet2(data?.secondApplicant?.previousAddress?.street ?? null)
				);
				store.dispatch(
					setPreviousStreetNumber2(
						data?.secondApplicant?.previousAddress?.streetNumber ?? null
					)
				);
				store.dispatch(
					setPreviousPostalCode2(
						data?.secondApplicant?.previousAddress?.postalCode ?? null
					)
				);
				store.dispatch(
					setPreviousCity2(data?.secondApplicant?.previousAddress?.city ?? null)
				);
			}

			store.dispatch(setNumberAdults2(data?.secondApplicant?.numberAdults ?? 1));
			store.dispatch(setNumberChildren2(data?.secondApplicant?.numberChildren ?? 0));
			store.dispatch(
				setNumberChildrenWithBenefit2(data?.secondApplicant?.numberChildrenWithBenefit ?? 0)
			);

			store.dispatch(setLivingCosts2(data?.secondApplicant?.livingCosts ?? null));
			store.dispatch(setChildSupport2(data?.secondApplicant?.childSupport ?? null));
			store.dispatch(setTypeOfLiving2(data?.secondApplicant?.typeOfLiving ?? null));
		}
	}
};
