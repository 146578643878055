import React, { useMemo } from 'react';
import m from 'utils/messages/messages';
import { formatMoneyValue } from 'utils/numbers/numbers';
import {
	getMonthlyRate,
	getProtectMonthlyRateByProtect,
	summedNetAmount,
	summedRuntime
} from 'utils/credit/credit';

import styles from './Overview.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

const Overview = () => {
	const storeProtect = useSelector((state: RootState) => state.Insurance.protect);
	const storeProtectCoverage = useSelector((state: RootState) => state.Insurance.protectCoverage);

	const formattedAmount = () => {
		return formatMoneyValue(summedNetAmount(), true, true);
	};

	const formattedRuntime = () => {
		const tempRuntime = summedRuntime();
		const extension = m(tempRuntime === 1 ? 'runtime.month' : 'runtime.months', 'fields');
		return tempRuntime + ' ' + extension;
	};

	const getFormattedMonthlyRate = () => {
		const value =
			getMonthlyRate() +
			getProtectMonthlyRateByProtect(storeProtect, storeProtectCoverage ?? 200);
		return formatMoneyValue(value, true, true);
	};

	const formattedMonthlyRate = useMemo(() => {
		return getFormattedMonthlyRate();
	}, [storeProtect, storeProtectCoverage]);

	return (
		<div className={styles.box}>
			<div className={styles.col}>
				<div className={styles.label}>{m('amount.label', 'fields')}</div>
				<div className={styles.text}>{formattedAmount()}</div>
			</div>
			<div className={styles.col}>
				<div className={styles.label}>{m('runtime.labelLong', 'fields')}</div>
				<div className={styles.text}>{formattedRuntime()}</div>
			</div>
			<div className={styles.col}>
				<div className={styles.label}>
					{m(`rate.${storeProtect ? 'protectLong' : 'label'}`, 'fields')}
				</div>
				<div className={styles.text}>{formattedMonthlyRate}</div>
			</div>
		</div>
	);
};
export default Overview;
