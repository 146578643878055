import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { DEFAULT_INPUT } from './utils/regex';
import { useRefUtils } from 'utils/refs/refs';

import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import TextInputType from './types/TextInputType.d';
import TextInputAutocomplete from './types/TextInputAutocomplete.d';

import inputStyles from '../index.module.scss';

type Props = {
	value: string;
	setValue: Function;
	label: string | JSX.Element;
	type?: TextInputType;
	disabled?: boolean;
	hasError?: boolean;
	message?: string | JSX.Element;
	tabindex?: number;
	maxLength?: number;
	nextRefOnMaxLength?: any;
	autoComplete?: TextInputAutocomplete;
	name?: string;
	tooltip?: string | JSX.Element;
	suffix?: string | JSX.Element;
	regex?: RegExp;
	format?: Function;
	testId?: string;
};

export const TextInput = forwardRef((props: Props, ref) => {
	const [id] = useState(uuid());
	const inputRef: any = ref ? ref : useRef(null);

	const { focusRef } = useRefUtils();

	const [prevValue, setPrevValue] = useState(props.value ?? '');

	useEffect(() => {
		setPrevValue(props.value);
	}, [props.value]);

	const getType = () => {
		return props.type ?? 'text';
	};

	const onChange = (event: any) => {
		if (props.format) {
			props.format(event, prevValue, ref, (formattedValue: string) => {
				onChangeValue(formattedValue);
			});
		} else {
			onChangeValue(event.target.value);
		}
	};

	const onChangeValue = (value: string) => {
		if (props.regex) {
			if (props.regex.test(value) || value === '') {
				props.setValue(value);
				jumpToNextOnMaxLength(value);
			}
		} else {
			if (DEFAULT_INPUT.test(value) || value === '') {
				props.setValue(value);
				jumpToNextOnMaxLength(value);
			}
		}
	};

	const jumpToNextOnMaxLength = (value: string) => {
		if (props.nextRefOnMaxLength && props.maxLength && value.length >= props.maxLength) {
			focusRef(inputRef, props.nextRefOnMaxLength);
		}
	};

	return (
		<div
			className={classNames([
				inputStyles.wrapper,
				!!props.message ? inputStyles.hasMessage : null
			])}
		>
			<input
				ref={inputRef}
				id={id}
				className={classNames([
					inputStyles.input,
					props.value && props.value.length > 0 ? inputStyles.active : null,
					props.hasError ? inputStyles.error : null,
					props.hasError ? 'input--error' : null,
					props.tooltip ? inputStyles.hasTooltip : null,
					props.suffix ? inputStyles.hasSuffix : null
				])}
				value={props.value ?? ''}
				onChange={onChange}
				type={getType()}
				disabled={props.disabled}
				maxLength={props.maxLength}
				pattern={props.type === 'number' ? '\\d*' : null}
				name={props.autoComplete ?? null}
				autoComplete={props.autoComplete ?? id ?? 'off'}
				tabIndex={props.tabindex ?? undefined}
				data-testid={props.testId ?? null}
			/>
			<label htmlFor={id} className={inputStyles.label}>
				{props.label}
			</label>
			{props.tooltip && (
				<div className={inputStyles.tooltip}>
					<TooltipIcon text={props.tooltip} />
				</div>
			)}
			{props.suffix && <div className={inputStyles.suffix}>{props.suffix}</div>}
			{!!props.message && (
				<div
					className={classNames([
						inputStyles.message,
						props.message ? inputStyles.show : null
					])}
				>
					{props.message}
				</div>
			)}
		</div>
	);
});
export default TextInput;
