import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import Check from 'components/Atoms/Partials/Check/Check';

import inputStyles from 'components/Atoms/Form/index.module.scss';
import styles from './RadioButtonInput.module.scss';

type Props = {
	value: string;
	setValue: Function;
	label?: string | JSX.Element;
	items: Array<any>;
	size?: string;
	btnStyle?: string;
	disabled?: boolean;
	interactionDisabled?: boolean;
	hasError?: boolean;
	message?: string | JSX.Element;
	tabindex?: number;
	testId?: string;
};

const RadioButtonInput = (props: Props) => {
	const [id] = useState(uuid());

	const onChange = (event: any) => {
		props.setValue(event.target.value);
	};

	const getButtonSize = () => {
		switch (props.size) {
			case 's':
				return styles.s;
			default:
				return styles.m;
		}
	};

	const getButtonStyle = () => {
		switch (props.btnStyle) {
			case 'switch':
				return styles.styleSwitch;
			case 'tab':
				return styles.styleTab;
			case 'button':
				return styles.styleButton;
			default:
				return styles.styleDefault;
		}
	};

	return (
		<div
			className={classNames([
				styles.wrapper,
				props.interactionDisabled ? inputStyles.interactionDisabled : null,
				props.interactionDisabled ? styles.interactionDisabled : null,
				!!props.message ? inputStyles.hasMessage : null,
				props.hasError ? styles.error : null,
				props.hasError ? 'input--error' : null
			])}
			data-testid={props.testId ?? null}
		>
			{props.label && <div className={styles.label}>{props.label}</div>}
			<div
				className={classNames([
					styles.inner,
					props.btnStyle === 'tab' ? styles.noSpace : null,
					!props.btnStyle || props.btnStyle === 'default' ? styles.column : null
				])}
			>
				{props.items && props.items.length > 0 && (
					<>
						{props.items.map((item, index) => (
							<div key={id + '-' + index} className={styles.item}>
								<input
									id={id + '-' + index}
									type="radio"
									className={classNames([
										styles.radio,
										props.interactionDisabled
											? styles.interactionDisabled
											: null
									])}
									value={item.value}
									checked={props.value + '' === item.value + ''}
									onChange={onChange}
									disabled={props.disabled || props.interactionDisabled}
									tabIndex={(props.tabindex ?? 0) + 0.01 * index}
								/>
								<label
									htmlFor={id + '-' + index}
									className={classNames([
										styles.itemLabel,
										getButtonSize(),
										getButtonStyle()
									])}
								>
									<>
										{!props.btnStyle || props.btnStyle === 'default' ? (
											<>
												<span className={styles.check}>
													<Check
														active={
															props.value + '' === item.value + ''
														}
													/>
												</span>
												<span className={styles.textWrapper}>
													<span className={styles.text}>{item.text}</span>
													{item.textExtension && (
														<em className={styles.textExtension}>
															{item.textExtension}
														</em>
													)}
												</span>
											</>
										) : (
											<>{item.text}</>
										)}
									</>
								</label>
							</div>
						))}
					</>
				)}
			</div>
			{!!props.message && (
				<div
					className={classNames([
						styles.message,
						inputStyles.message,
						!props.btnStyle || props.btnStyle === 'default'
							? styles.messageDefault
							: null,
						props.message ? inputStyles.show : null
					])}
				>
					{props.message}
				</div>
			)}
		</div>
	);
};
export default RadioButtonInput;
