import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import m from 'utils/messages/messages';
import { continueValid } from 'utils/tracking/tracking';
import { goToPage } from 'utils/pages/pages';
import { hasMissingDocuments } from 'utils/upload/upload';

import Page from 'components/Organisms/Page/Page';
import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { Col, Row } from 'components/Atoms/Grid';
import { MultipleFileUpload } from 'components/Atoms/Form';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import Subpage from 'types/enums/Subpage.d';
import ProcessOption from 'types/enums/ProcessOption.d';
import MissingDocument from 'types/interfaces/MissingDocument.d';
import { postApplicationsDocuments } from 'api';

import styles from './UploadContractDocuments.module.scss';

const UploadContractDocuments = () => {
	const { processOption } = useParams();

	const hasSecondApplicant = useSelector(
		(state: RootState) => state.Applicant.hasSecondApplicant
	);
	const storeShowProtect = useSelector((state: RootState) => state.Company.showProtect);
	const storeProtectAmount = useSelector((state: RootState) => state.Bco.protectAmount);

	const [missingDocuments, setMissingDocuments] = useState<Array<MissingDocument>>([]);
	const [hasSecondApplicantDocuments, setHasSecondApplicantDocuments] = useState<boolean>(false);

	const [option, setOption] = useState<ProcessOption>(ProcessOption.COMBINATION);

	useEffect(() => {
		parseParam();
	}, []);

	const parseParam = () => {
		if (processOption === 'kombination') {
			setOption(ProcessOption.COMBINATION);
			getApplicationsMissingDocuments(ProcessOption.COMBINATION);
		} else if (processOption === 'mitarbeiter') {
			setOption(ProcessOption.AGENT);
			getApplicationsMissingDocuments(ProcessOption.AGENT);
		}
	};

	const getApplicationsMissingDocuments = (parsedOption: ProcessOption) => {
		const tempMissingDocuments: Array<MissingDocument> = [];
		if (parsedOption === ProcessOption.AGENT) {
			tempMissingDocuments.push({
				id: 134,
				name: 'Kreditvertrag',
				description: '',
				applicant: 1,
				origin: 'MANUAL_UPLOAD',
				file: []
			});
		}
		if (parsedOption === ProcessOption.COMBINATION || parsedOption === ProcessOption.AGENT) {
			tempMissingDocuments.push({
				id: 144,
				name: 'Ausweiskopie',
				description: 'Kopie des Personalausweises oder Reisepasses',
				applicant: 1,
				origin: 'MANUAL_UPLOAD',
				file: []
			});
			if (hasSecondApplicant) {
				tempMissingDocuments.push({
					id: 144,
					name: 'Ausweiskopie',
					description: 'Kopie des Personalausweises oder Reisepasses',
					applicant: 2,
					origin: 'MANUAL_UPLOAD',
					file: []
				});
				setHasSecondApplicantDocuments(true);
			}
			if (storeShowProtect && !!storeProtectAmount) {
				tempMissingDocuments.push({
					id: 108,
					name: 'Protect Vertrag',
					description: '',
					applicant: 1,
					origin: 'MANUAL_UPLOAD',
					file: []
				});
			}
		}
		setMissingDocuments(tempMissingDocuments);
	};

	const onFormSubmit = async () => {
		await postApplicationsDocuments(
			missingDocuments,
			() => {
				continueValid();
				if (option === ProcessOption.AGENT) {
					goToPage(Subpage.CREDIT_DONE);
				} else {
					goToPage(Subpage.EMAIL_SENT_TO_CUSTOMER);
				}
			},
			(statusCode?: number) => {
				console.log(statusCode);
			}
		);
	};

	return (
		<Page>
			<Title>{m('page.uploadMissingDocuments.title', 'global')}</Title>
			<Text isSubtitle>{m('page.uploadMissingDocuments.subtitle', 'global')}</Text>
			<Form
				showSubmitDivider
				onSubmit={onFormSubmit}
				submitDisabled={hasMissingDocuments(missingDocuments)}
				submitDisabledTooltip={m(
					'page.uploadMissingDocuments.submitDisabledTooltip',
					'global'
				)}
				onPrevButton={() => {
					goToPage(Subpage.FINISH_PROCESS);
				}}
			>
				{hasSecondApplicantDocuments && (
					<Title hasMarginBottom isSmall>
						{m('partial.applicant.first', 'global')}
					</Title>
				)}
				<Row justifyContent="center">
					{missingDocuments.map((item, index) => {
						if (item.applicant !== 1) return null;
						return (
							<Col xs={12} md={4} key={'missing-documents-first-applicant-' + index}>
								<div className={styles.inputWrapper}>
									<MultipleFileUpload
										index={index + 1}
										id={item.id}
										name={item.name}
										description={item.description}
										init={item.file ? item.file : []}
										missingDocuments={missingDocuments}
										setMissingDocuments={setMissingDocuments}
										allowJpg={true}
										allowPng={true}
										allowPdf={true}
										applicant={item.applicant}
									/>
								</div>
							</Col>
						);
					})}
				</Row>
				{hasSecondApplicantDocuments && (
					<>
						<Title hasMarginBottom isSmall>
							{m('partial.applicant.second', 'global')}
						</Title>
						<Row justifyContent="center">
							{missingDocuments.map((item, index) => {
								if (item.applicant !== 2) return null;
								return (
									<Col
										xs={12}
										md={4}
										key={'missing-documents-second-applicant-' + index}
									>
										<div className={styles.inputWrapper}>
											<MultipleFileUpload
												index={index + 1}
												id={item.id}
												name={item.name}
												description={item.description}
												init={item.file ? item.file : []}
												missingDocuments={missingDocuments}
												setMissingDocuments={setMissingDocuments}
												allowJpg={true}
												allowPng={true}
												allowPdf={true}
												applicant={item.applicant}
											/>
										</div>
									</Col>
								);
							})}
						</Row>
					</>
				)}
			</Form>
		</Page>
	);
};
export default UploadContractDocuments;
