import React from 'react';
import m from 'utils/messages/messages';

import { Text } from 'components/Atoms/Text';
import BoxCollapsible from 'components/Molecules/Box/BoxCollapsible/BoxCollapsible';
import SvgScale from 'components/Atoms/SVG/SvgScale';
import Overview from 'components/Molecules/Form/Insurance/Overview/Overview';
import ProtectBody from 'components/Molecules/Form/Insurance/Protect/Protect';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

const Insurance = () => {
	const companyShowProtect = useSelector((state: RootState) => state.Company.showProtect);

	return (
		<>
			{companyShowProtect && (
				<>
					<Text isSubtitle hasMarginBottom>
						{m('page.customerData.insurance.title', 'global')}
					</Text>
					<Overview />
					<BoxCollapsible
						activeDefault
						icon={<SvgScale />}
						title={m('page.customerData.insurance.protect.title', 'global')}
						subtitle={m('page.customerData.insurance.protect.subtitle', 'global')}
						contentBody={<ProtectBody />}
					/>
				</>
			)}
		</>
	);
};

export default Insurance;
