import axios from 'axios';
import store from 'store/store';
import { setApplications } from 'store/Applications/Applications';
import { getApiBaseURL } from 'utils/api/api';
import { showApiConnectionError } from 'utils/validation/validation';
import { setIsLoadingPage } from 'store/Loading/Loading';
const url = getApiBaseURL() + '/api/status-overview/applications';

export const getStatusOverviewApplications = async (
	successCb: Function = () => {},
	errorCb: Function = () => {}
) => {
	store.dispatch(setIsLoadingPage(true));
	await axios
		.get(url)
		.then((res) => {
			onSuccess(res.data, successCb);
		})
		.catch((err: any) => {
			onError(err, errorCb);
		});
};

const onSuccess = (data: Array<any>, successCb: Function = () => {}) => {
	store.dispatch(setIsLoadingPage(false));
	store.dispatch(setApplications(data));
	successCb(data);
};

const onError = (err: any, errorCb: Function = () => {}) => {
	store.dispatch(setIsLoadingPage(false));
	store.dispatch(setApplications([]));
	showApiConnectionError();
	errorCb();
};
