import { isEmpty } from 'lodash';
import store from 'store/store';
import { amountDefault, amountMaxValue, runtimeDefault, runtimeSteps } from 'constants/Credit';

import { roundUp } from 'utils/numbers/numbers';
// import { getAgeWithRuntime } from 'utils/date/date';

import ExternalCredit from 'types/interfaces/ExternalCredit.d';
import Protect from 'types/enums/Protect.d';

export const getMonthlyRate = (
	amount?: number,
	runtime?: number,
	summedExternalCredits?: number
) => {
	if (amount !== undefined && runtime !== undefined && summedExternalCredits !== undefined) {
		const jsonData = getMonthlyRateJsonData(amount, runtime, summedExternalCredits);
		return jsonData ? jsonData[jsonData.length - 1] : 0;
	} else {
		const jsonData = getMonthlyRateJsonData();
		return jsonData ? jsonData[jsonData.length - 1] : 0;
	}
};

export const getNormalizedRuntime = (runtime: number) => {
	let normalizedRuntime: number;
	const index: number = runtimeSteps.findIndex((defaultRuntime) => {
		return runtime < defaultRuntime;
	});

	if (index === -1) {
		normalizedRuntime = runtimeSteps[runtimeSteps.length - 1];
	} else if (index === 0) {
		normalizedRuntime = runtimeSteps[0];
	} else {
		normalizedRuntime = runtimeSteps[index - 1];
	}

	return normalizedRuntime;
};

export const getNormalizedAmount = (amount: number) => {
	return roundUp(amount, 500, amountMaxValue);
};

export const getJsonData = () => {
	return store.getState().Credit.ccbData.creditValues
		? store.getState().Credit.ccbData.creditValues
		: {};
};

export const getMonthlyRateJsonData = (
	amount?: number,
	runtime?: number,
	summedExternalCredits?: number
) => {
	const jsonData = getJsonData();
	if (!isEmpty(jsonData)) {
		let normalizedAmountString;
		let normalizedRuntimeString;
		if (amount !== undefined && runtime !== undefined && summedExternalCredits !== undefined) {
			normalizedAmountString = getNormalizedAmount(amount + summedExternalCredits).toString();
			normalizedRuntimeString = getNormalizedRuntime(runtime).toString();
		} else {
			normalizedAmountString = getNormalizedAmount(summedNetAmount()).toString();
			normalizedRuntimeString = getNormalizedRuntime(summedRuntime()).toString();
		}
		try {
			return jsonData[normalizedAmountString][normalizedRuntimeString];
		} catch (e) {
			return null;
		}
	} else {
		return null;
	}
};

export const getSummedExternalCreditAmount = (
	customExternalCredits: Array<ExternalCredit> = null
) => {
	const externalCredits: Array<ExternalCredit> = customExternalCredits
		? customExternalCredits
		: store.getState().Credit.externalCredits;
	let summedRemainingBalance: number = 0;
	for (const externalCredit of externalCredits) {
		summedRemainingBalance += externalCredit.remainingBalance
			? externalCredit.remainingBalance
			: 0;
	}
	return summedRemainingBalance;
};

export const summedNetAmount = () => {
	const amount = store.getState().Credit.amount ?? amountDefault;
	return amount + getSummedExternalCreditAmount();
};

export const summedRuntime = () => {
	const runtime = store.getState().Credit.runtime ?? runtimeDefault;
	return runtime ?? 0;
};

export const totalAmount = () => {
	return summedNetAmount() + getInterest();
};

export const mapCurrentRuntimeToYears = () => {
	const runtime = store.getState().Credit.runtime ?? runtimeDefault;
	return Math.ceil(runtime / 12);
};

export const getProtectValues = (protectCoverage: number) => {
	const jsonData = require('data/protect.json');
	if (protectCoverage) {
		return jsonData[protectCoverage + ''];
	} else {
		return null;
	}
};

export const getProtectMonthlyRateByProtect = (protect: string, protectCoverage: number) => {
	const protectValues = getProtectValues(protectCoverage ?? 200);
	let protectMonthlyRate = 0;
	const protectFormatted = protect ? protect.toLowerCase() : null;
	if (protectFormatted === Protect.STANDARD) {
		protectMonthlyRate = protectValues[1];
	} else if (protectFormatted === Protect.PREMIUM) {
		protectMonthlyRate = protectValues[2];
	} else if (protectFormatted === Protect.BASIC) {
		protectMonthlyRate = protectValues[0];
	}
	return protectMonthlyRate;
};

export const getProtectAmount = (protect: string, protectCoverage: number) => {
	const protectMonthlyRate = getProtectMonthlyRateByProtect(protect, protectCoverage ?? 200);
	return protectMonthlyRate * 60;
};

export const getInterest = () => {
	try {
		const jsonData = getMonthlyRateJsonData();
		return jsonData[2];
	} catch (e) {
		return 0;
	}
};

export const getEffectiveYearlyInterestRate = () => {
	try {
		const jsonData = getMonthlyRateJsonData();
		return jsonData[0];
	} catch (e) {
		return 0;
	}
};
