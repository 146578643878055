import { orderBy } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Company from './Company.d';

const initialState: Company = {
	logoFilename: null,
	name: null,
	conditionPrograms: [],
	conditionProgram: null,
	showProtect: true
};

export const slice = createSlice({
	name: 'Company',
	initialState: initialState,
	reducers: {
		setCompanyData: (state, action: PayloadAction<Company>) => {
			state.logoFilename = action.payload.logoFilename ?? null;
			state.name = action.payload.name ?? null;
			if (action.payload.conditionPrograms && action.payload.conditionPrograms.length > 0) {
				state.conditionPrograms = orderBy(
					action.payload.conditionPrograms,
					[(item: any) => item.minInterest],
					['asc']
				);
			} else {
				state.conditionPrograms = [];
			}
			state.showProtect = action.payload.showProtect ?? true;
		},
		setConditionProgram: (state, action: PayloadAction<number>) => {
			state.conditionProgram = action.payload;
		},
		setShowProtect: (state, action: PayloadAction<boolean>) => {
			state.showProtect = action.payload;
		},
		reset: () => initialState
	}
});

export const { setCompanyData, setConditionProgram, setShowProtect, reset } = slice.actions;

export default slice.reducer;
