import React, { useMemo } from 'react';
import m from 'utils/messages/messages';
import { DIGIT, IBAN } from 'components/Atoms/Form/TextInput/utils/regex';
import { getTypeOfLivingItems } from 'utils/items/items';
import { formatIbanForInput } from 'utils/iban/iban';

import { Text } from 'components/Atoms/Text';
import { Col, Row } from 'components/Atoms/Grid';
import { DropdownInput, TextInput } from 'components/Atoms/Form';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

type Props = {
	iban?: string;
	setIban?: Function;
	ibanError?: string;
	income: number;
	setIncome: Function;
	incomeError?: string;
	additionalIncome: number;
	setAdditionalIncome: Function;
	livingCosts?: number;
	setLivingCosts?: Function;
	livingCostsError?: string;
	childSupport?: number;
	setChildSupport?: Function;
	typeOfLiving?: string;
	setTypeOfLiving?: Function;
	typeOfLivingError?: string;
};

const FinancialStatus = (props: Props) => {
	const storeHasSecondApplicant = useSelector(
		(state: RootState) => state.Applicant.hasSecondApplicant
	);
	const storeSameHousehold = useSelector((state: RootState) => state.Applicant.sameHousehold);

	const typeOfLivingItems = getTypeOfLivingItems();

	const hasIban = useMemo(() => {
		return !!props.setIban;
	}, [props.setIban]);

	const hasLivingCostsAndOtherAndTypeOfLiving = useMemo(() => {
		return !!(props.setLivingCosts && props.setChildSupport && props.setTypeOfLiving);
	}, [props.setLivingCosts, props.setChildSupport, props.setTypeOfLiving]);

	return (
		<>
			{hasIban && (
				<>
					<Text isSubtitle>{m('page.customerData.iban.title', 'global')}</Text>
					<Row>
						<Col xs={12}>
							<TextInput
								label={m('iban.label', 'fields')}
								value={props.iban}
								setValue={props.setIban}
								format={formatIbanForInput}
								regex={IBAN}
								maxLength={40}
								hasError={!!props.ibanError}
								message={props.ibanError ?? m('iban.placeholder', 'fields')}
								tabindex={34}
								testId="iban"
							/>
						</Col>
					</Row>
				</>
			)}
			<Text isSubtitle>{m('page.customerData.financialStatus.title', 'global')}</Text>
			<Row>
				<Col xs={12} sm={6}>
					<TextInput
						label={m('income.label', 'fields')}
						value={props.income ? props.income + '' : ''}
						setValue={props.setIncome}
						regex={DIGIT}
						hasError={!!props.incomeError}
						message={props.incomeError}
						tabindex={35}
						testId="income"
					/>
				</Col>
				<Col xs={12} sm={6}>
					<TextInput
						label={m('additionalIncome.label', 'fields')}
						value={props.additionalIncome ? props.additionalIncome + '' : ''}
						setValue={props.setAdditionalIncome}
						regex={DIGIT}
						tabindex={36}
						testId="additional-income"
					/>
				</Col>
			</Row>
			{hasLivingCostsAndOtherAndTypeOfLiving && (
				<>
					<Text isSubtitle>
						{m('page.customerData.financialStatus.livingCosts', 'global')}
					</Text>
					<Row>
						<Col xs={12} sm={6}>
							<TextInput
								label={
									storeHasSecondApplicant && storeSameHousehold
										? m('livingCosts.sameHousehold', 'fields')
										: m('livingCosts.label', 'fields')
								}
								value={props.livingCosts ? props.livingCosts + '' : ''}
								setValue={props.setLivingCosts}
								regex={DIGIT}
								hasError={!!props.livingCostsError}
								message={props.livingCostsError}
								tabindex={37}
								testId="livingCosts"
							/>
						</Col>
						<Col xs={12} sm={6}>
							<TextInput
								label={
									storeHasSecondApplicant && storeSameHousehold
										? m('childSupport.sameHousehold', 'fields')
										: m('childSupport.label', 'fields')
								}
								value={props.childSupport ? props.childSupport + '' : ''}
								setValue={props.setChildSupport}
								regex={DIGIT}
								tabindex={38}
								testId="other-expenses"
							/>
						</Col>
						<Col xs={12}>
							<DropdownInput
								label={m('typeOfLiving.label', 'fields')}
								value={props.typeOfLiving}
								setValue={props.setTypeOfLiving}
								items={typeOfLivingItems}
								hasError={!!props.typeOfLivingError}
								message={props.typeOfLivingError}
								tabindex={39}
								testId="type-of-living"
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};
export default FinancialStatus;
